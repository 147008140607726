<template>
    <v-container>
        <v-row>
            <v-col class="text-center primary lighten-1 rounded">
                <v-icon class="rounded-circle white pa-3" 
                        x-large 
                        color="accent">mdi-account</v-icon>
                <div class="text-h5 white--text font-weight-light pt-3">{{ Nombre }} {{ Apellido }}</div>
                <div class="text-subtitle-1 white--text font-weight-medium">{{ Email }}</div>
                <div class="text-subtitle-1 white--text font-weight-medium">{{ Celular }}</div>
            </v-col>
        </v-row>
        <v-btn 
            block 
            dark 
            class="mt-7 secondary lighten-1" 
            to="Login"
            @click="LogOut"
            >Cerrar sesion</v-btn>
        <div class="mt-15 text-center text-caption font-weight-light">Version 1.0</div>
    </v-container>
</template>
<script>
export default {
    data(){
        return{
            Nombre: '',
            Apellido: '',
            Email: '',
            Celular: ''
        }
    },
    methods:{
        LogOut(){
            localStorage.setItem('id', '')
            localStorage.setItem('nombre', '')
            localStorage.setItem('apellido', '')
            localStorage.setItem('email', '')
            localStorage.setItem('celular', '')
            localStorage.setItem('token', '')
        }
    },
    mounted(){
        this.Nombre = localStorage.getItem('nombre')
        this.Apellido = localStorage.getItem('apellido')
        this.Email = localStorage.getItem('email')
        this.Celular = localStorage.getItem('celular')
    },
    created(){
    this.$emit('muestra', false)
    }
}
</script>